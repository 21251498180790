import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import Icon from '~/features/common/components/icons/Icon/index';

const DropDownIcon = ({
  className,
  onClick,
  testId,
  opened,
  children,
}: {
  className?: string;
  onClick?: () => void;
  testId?: string;
  tooltip?: string;
  opened: boolean;
  children?: string | ReactElement;
}) => {
  return (
    <>
      <Icon
        icon={opened ? faChevronDown : faChevronRight}
        className={className}
        onClick={() => onClick && onClick()}
        data-testid={testId}
      />
      {children}
    </>
  );
};
export default DropDownIcon;
