/* This example requires Tailwind CSS v2.0+ */
import clsx from 'clsx';
import { ReactElement } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from './Sidebar/index';

export default function AppLayout({ children }: { children: ReactElement }) {
  const auth = useAuth();
  const showSidebar = !!auth.user;
  return (
    <div className="flex h-screen overflow-hidden bg-white dark:bg-gray-950 print:h-auto">
      {showSidebar && (
        <div className={clsx(showSidebar ? 'flex flex-shrink-0' : 'hidden')}>
          <Sidebar />
        </div>
      )}
      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        <div className="relative z-0 flex flex-1 overflow-hidden">
          <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none">
            <div className="print: absolute inset-0 p-2">
              <div className="relative h-full print:h-auto">{children}</div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
}
