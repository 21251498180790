import {
  dehydrate,
  hydrate,
  MutationKey,
  MutationOptions,
  QueryClient,
} from '@tanstack/react-query';
import { del, get, set } from 'idb-keyval';
import { merge } from 'lodash';
import {
  GetCarSetupQuery,
  GetRunSheetByIdQuery,
  UpdateCarSetupDocument,
  UpdateCarSetupMutation,
  UpdateCarSetupMutationVariables,
  UpdateRunsheetDocument,
  UpdateRunsheetMutation,
  UpdateRunsheetMutationVariables,
} from '~/generated/graphql';
import packagejson from '../../package.json';
import config from '../config';
import { fetcher } from './fetcher';

interface IndexedDBCache {
  timestamp: number;
  buster: string;
  cacheState: unknown;
}

const indexedDBKey = `REACT_QUERY_OFFLINE_CACHE`;
const buster = packagejson.version || '';
const maxAge = 1000 * 60 * 60 * 24 * 7;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 1000 * 60 * 60 * 24 * 7, // 7 days cacheTime,
    },
  },
});

queryClient.setMutationDefaults(['UpdateRunsheetMutation'], {
  mutationFn: (variables) =>
    fetcher<UpdateRunsheetMutation, UpdateRunsheetMutationVariables>(
      UpdateRunsheetDocument,
      variables
    )(),
  onMutate: async (runSheet) => {
    await queryClient.cancelQueries(['GetRunSheetByID', { id: runSheet.id }]);

    const previousData = queryClient.getQueryData([
      'GetRunSheetByID',
      { id: runSheet.id },
    ]);
    queryClient.setQueryData(
      ['GetRunSheetByID', { id: runSheet.id }],
      (oldData: { runSheet: GetRunSheetByIdQuery['runSheet'] } | undefined) => {
        const runSheetMerged = merge({ ...oldData?.runSheet }, runSheet.record);
        return {
          runSheet: runSheetMerged,
        };
      }
    );

    return { previousData, runSheet };
  },
  retry: 3,
});

queryClient.setMutationDefaults(['UpdateCarSetupMutation'], {
  mutationFn: (variables) =>
    fetcher<UpdateCarSetupMutation, UpdateCarSetupMutationVariables>(
      UpdateCarSetupDocument,
      variables
    )(),
  onMutate: async (carSetup) => {
    await queryClient.cancelQueries(['GetCarSetup', { id: carSetup.id }]);

    const previousData = queryClient.getQueryData([
      'GetCarSetup',
      { id: carSetup.id },
    ]);
    queryClient.setQueryData(
      ['GetCarSetup', { id: carSetup.id }],
      (oldData: { carSetup: GetCarSetupQuery['carSetup'] } | undefined) => {
        const setupMerged = merge({ ...oldData?.carSetup }, carSetup.record);
        return {
          carSetup: setupMerged,
        };
      }
    );

    return { previousData, carSetup };
  },
  retry: 3,
});

export const saveCache = async () => {
  if (typeof window !== 'undefined' && window.indexedDB) {
    const storageCache: IndexedDBCache = {
      buster,
      timestamp: Date.now(),
      cacheState: dehydrate(queryClient, { dehydrateMutations: false }),
    };

    const mutations = queryClient
      .getMutationCache()
      .findAll({
        predicate: (mutation) => mutation.state.status === 'loading',
      })
      .map((item) => ({
        mutationKey: item.options.mutationKey,
        variables: item.state.variables,
      }));

    config.set('mutations', mutations);
    await set(indexedDBKey, JSON.stringify(storageCache)); // set in Indexed DB
  }
};

const loadCache = async () => {
  if (typeof window !== 'undefined' && window.indexedDB) {
    // Attempt restore
    const cacheStorage = await get(indexedDBKey); // get from Indexed DB

    if (cacheStorage) {
      const cache: IndexedDBCache = JSON.parse(cacheStorage);

      if (cache.timestamp) {
        const expired = Date.now() - cache.timestamp > maxAge;
        const busted = cache.buster !== buster;
        if (expired || busted) {
          del(indexedDBKey); // Delete from Indexed DB
        } else {
          const mutations = config.get('mutations');
          hydrate(queryClient, cache.cacheState);

          if (mutations) {
            mutations.forEach(
              (mutation: { mutationKey: MutationKey; variables: unknown }) => {
                executeMutation({
                  mutationKey: mutation.mutationKey,
                  variables: mutation.variables,
                });
              }
            );
          }
        }
      } else {
        del(indexedDBKey);
      }
    }
  }
};

function executeMutation<
  TData = unknown,
  TError = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: MutationOptions<TData, TError, TVariables, TContext>
): Promise<TData> {
  return queryClient.getMutationCache().build(queryClient, options).execute();
}

loadCache();
