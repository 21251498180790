import { Component, ErrorInfo, ReactNode } from 'react';
import WarningMessage from '~/features/common/components/WarningMessage';
import { logger } from '../../utils/logger';

interface Props {
  children: ReactNode;
  isFullPageError?: boolean;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return (
        <WarningMessage
          isFullPageError={this.props.isFullPageError}
          level="red"
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
