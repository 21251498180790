import { createContext, ReactNode, useContext } from 'react';
import useProvideAuth from '../../auth/hooks/useProvideAuth';
import { checkifIsProUser } from '../utils/loginChecks';

export interface AuthInterface {
  signin: (token: string, cb: () => void) => void;
  signout: () => void;
  isLoggedIn: boolean;
  user: string | null;
}

type AuthContextInterface = AuthInterface & {
  isProUser: boolean;
  isLoading: boolean;
};

const defaultAuth: AuthContextInterface = {
  signin: () => {},
  signout: () => {},
  isLoggedIn: false,
  isProUser: false,
  isLoading: false,
  user: null,
};

const authContext = createContext<AuthContextInterface>(defaultAuth);

export const ProvideAuth = ({ children }: { children: ReactNode }) => {
  const auth = useProvideAuth();
  const { isProUser, isLoading } = checkifIsProUser();
  return (
    <authContext.Provider value={{ ...auth, isProUser, isLoading }}>
      {children}
    </authContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(authContext);
};

export default authContext;
