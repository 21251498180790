import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useState } from 'react';
import { Provider } from 'urql';
import Routes from './Routes';
import isElectron from './features/common/utils/isElectron';
import { queryClient, saveCache } from './graphql/queryClient';
import { urqlClient } from './graphql/urqlClient';

export default function Root() {
  const [showClosingModal, setShowClosingModal] = useState(false);

  if (isElectron()) {
    const { ipcRenderer } = window.require('electron');

    ipcRenderer.on('mainprocess-request-close', () => {
      setShowClosingModal(true);
      saveCache().then(() => {
        ipcRenderer.send('request-app-close');
      });
    });
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Provider value={urqlClient}>
        <Routes />
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        {showClosingModal && (
          <div className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white text-lg">
            Saving data - Please wait...
          </div>
        )}
      </Provider>
    </QueryClientProvider>
  );
}
