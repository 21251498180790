import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { useState } from 'react';
import Icon from '~/features/common/components/icons/Icon/index';
import { timeHMSString } from '~/features/common/utils/timeString';
import { SessionTimeCount } from '~/features/protocol/runsheets/components/SessionTimeCount';
import { useGetSessionQuery } from '~/generated/graphql';
import { queryClient } from '~/graphql/queryClient';
import config from '../../../../config';
import { getUserID } from '../../../common/utils/loginChecks';

export const SessionTimeRemaining = (sessionID: { sessionID: string }) => {
  const personID = getUserID();
  const { data: sessionInfo } = useGetSessionQuery({ id: sessionID.sessionID });

  const [currentTime, setCurrentTime] = useState(() => new Date().getTime());

  // Re fetch to get updated session time remaining every 30 seconds
  setInterval(() => {
    queryClient.invalidateQueries(['GetSession', { id: sessionID.sessionID }]);
  }, 30000);

  const timeRemainingUpdatedAt = sessionInfo?.session?.timeRemainingUpdatedAt;

  const timeRemaining = sessionInfo?.session?.timeRemaining ?? 0;

  const timeRemainingUpdatedAtDate = new Date(timeRemainingUpdatedAt);

  const timeRemainingDifference =
    currentTime - timeRemainingUpdatedAtDate.getTime();

  const timeRemainingDifferenceInSeconds = timeRemainingDifference / 1000;

  const timeRemainingInSeconds =
    timeRemaining - timeRemainingDifferenceInSeconds;

  let timer;

  const startTimer = () => {
    timer = setInterval(() => {
      setCurrentTime(new Date().getTime());
    }, 1000);
  };
  startTimer();

  if (timeRemainingInSeconds < 0) {
    clearInterval(timer);
  }

  const timeRemainingString = timeHMSString(timeRemainingInSeconds);
  return (
    <>
      <div className="z-50 border dark:border-gray-700">
        <div className="z-50 flex items-center justify-between bg-gray-200 p-1 dark:bg-gray-900">
          <div className="text-2xs">TRACK STATE</div>
          <Icon
            icon={faTimes}
            onClick={() => {
              config.set('session.showSessionTimeRemaining', false);
              queryClient.invalidateQueries([
                'GetPersonInfo',
                { id: personID },
              ]);
            }}
            className="hover:cursor-pointer"
          />
        </div>
        <SessionTimeCount
          timeRemainingInSeconds={timeRemainingInSeconds}
          timeRemainingString={timeRemainingString}
          sessionID={sessionID.sessionID}
        />
      </div>
    </>
  );
};
