const isDev = () => {
  let result;
  try {
    result = import.meta.env.DEV;
  } catch {
    return false;
  }
  return result;
};
export default isDev;
