import clsx from 'clsx';
import { Logo } from '~/features/common/assets/Logo';
import { useAuth } from '~/features/common/context/AuthContext';

const OneRaceHubLogo = ({ collapsed }: { collapsed: boolean }) => {
  const { isProUser } = useAuth();
  return (
    <div
      className={clsx(
        'group flex flex-shrink-0 items-center  text-sm font-medium text-gray-800 dark:text-gray-50',
        collapsed ? 'm-auto' : 'py-2 pl-3.5'
      )}
    >
      <Logo
        testId="logo-sidebar"
        classNames={clsx(collapsed ? 'h-5 w-5' : 'mr-1.5 h-6 w-6')}
      />
      {!collapsed && (
        <>
          <span>ONE RACEHUB</span>
          {isProUser && (
            <span className="mb-2 ml-0.5 text-2xs font-bold italic tracking-tight text-primary">
              PRO
            </span>
          )}
        </>
      )}
    </div>
  );
};

export default OneRaceHubLogo;
