import { faWifi } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useIsOnline } from '~/features/analysis/hooks/useIsOnline';
import CustomTooltipWrapper from '~/features/common/components/CustomTooltipWrapper/index';
import Icon from '~/features/common/components/icons/Icon/index';
import { getIndexPath as getManageLoginsPath } from '~/features/manage-logins/routes';
import { getHttpUrl } from '~/graphql/getUrl';
import { useGetCurrentServer } from '../../../hooks/useGetCurrentServer';

const ServerStatus = ({ collapsed }: { collapsed: boolean }) => {
  const navigate = useNavigate();
  const currentServer = useGetCurrentServer(getHttpUrl());
  const { isOnline } = useIsOnline();
  const onCurrentServerClicked = () => {
    navigate(getManageLoginsPath({}));
  };
  return (
    <button onClick={onCurrentServerClicked}>
      <CustomTooltipWrapper content={'Manage Logins'}>
        {!collapsed ? (
          <>
            <span
              className={clsx(
                'flex gap-x-1',
                ' dark:bg-gray-800',
                'text-3xs font-bold uppercase text-gray-600 dark:text-gray-100',
                'rounded-md ring-1 ring-inset ring-gray-300 dark:ring-gray-500'
              )}
            >
              <span className="py-0.5 pl-2">Server</span>
              <span
                className={clsx(
                  'flex items-center rounded-r-md px-1 text-white',
                  isOnline
                    ? 'bg-green-500 dark:bg-green-500/30 dark:text-white'
                    : 'bg-red-500 dark:bg-red-500/50'
                )}
              >
                {isOnline ? currentServer : 'Disconnected'}
              </span>
            </span>
          </>
        ) : (
          <span
            className={clsx(
              'flex items-center rounded-md text-white',
              isOnline
                ? 'bg-green-500  px-1 py-0.5 ring-green-500 dark:bg-green-300/30 dark:ring-1'
                : 'bg-red-500 px-2 py-0.5 dark:bg-red-500/30 dark:ring-1 dark:ring-red-500'
            )}
          >
            {isOnline ? (
              <span className="leading-1 text-3xs font-bold uppercase">
                {currentServer}
              </span>
            ) : (
              <div className="relative">
                <Icon icon={faWifi} aria-hidden="true" size="sm" />
                <div className="absolute top-[50%] h-[3px] w-[20px] origin-center -translate-x-0.5 -translate-y-0.5 rotate-45 rounded-sm border-t-2 border-red-500 bg-white"></div>
              </div>
            )}
          </span>
        )}
      </CustomTooltipWrapper>
    </button>
  );
};
export default ServerStatus;
