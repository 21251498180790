import { faWarning } from '@fortawesome/free-solid-svg-icons';
import WarningMessage from '~/features/common/components/WarningMessage/WarningMessage';
import Icon from '~/features/common/components/icons/Icon/index';
import { useGetVersionQuery } from '~/generated/graphql';
import packagejson from '../../../../../package.json';

const ApiVersionCheck = ({ collapsed }: { collapsed: boolean }) => {
  const versionQuery = useGetVersionQuery();
  const apiVersion = versionQuery.data?.version;

  if (versionQuery.isLoading) {
    return <></>;
  }

  if (!apiVersion) {
    return <></>;
  }

  const versionSplit = apiVersion.split('.');
  const reqSplit = packagejson.minApiVersion.split('.');
  const warningMessageText = `API Version Mismatch! <br />
<strong>Required: ${packagejson.minApiVersion} </strong><br />
Server: ${apiVersion}`;
  if (
    parseFloat(versionSplit[0]) < parseFloat(reqSplit[0]) ||
    parseFloat(versionSplit[1]) < parseFloat(reqSplit[1])
  ) {
    return !collapsed ? (
      <WarningMessage classNames="my-2 mx-1" level="yellow" textSize="text-xs">
        <span>
          API Version Mismatch! <br />
          <strong>Required: {packagejson.minApiVersion} </strong>
          <br />
          Server: {apiVersion}
        </span>
      </WarningMessage>
    ) : (
      <span className="my-3 flex justify-center text-yellow-500">
        <Icon html={warningMessageText} icon={faWarning} />
      </span>
    );
  }
  return <></>;
};

export default ApiVersionCheck;
