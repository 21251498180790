import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { MouseEvent, ReactNode } from 'react';
import { ITooltipWrapper } from 'react-tooltip';
import CustomTooltipWrapper from '~/features/common/components/CustomTooltipWrapper/index';

export type IconAtomProps = FontAwesomeIconProps & {
  jsx?: ReactNode | null;
  icon: IconDefinition;
  content?: string;
  html?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  place?: ITooltipWrapper['place'];
};

const Icon = (props: IconAtomProps) => {
  const { jsx, icon, content, html, onClick, place, ...otherProps } = props;

  return (
    <CustomTooltipWrapper content={content} jsx={jsx} html={html} place={place}>
      <FontAwesomeIcon icon={icon} onClick={onClick} {...otherProps} />
    </CustomTooltipWrapper>
  );
};

export default Icon;
