import { useAuth } from '~/features/common/context/AuthContext';

export const ProLabel = ({ collapsed }: { collapsed: boolean }) => {
  const { isProUser, isLoading } = useAuth();
  if (isLoading) {
    return <></>;
  }
  if (isProUser) {
    return <></>;
  }
  return !collapsed ? (
    <>
      <div className="absolute bottom-0 right-2 top-0 flex h-full items-center">
        <div className="h-4 rounded-full bg-gray-600 px-1.5 text-2xs font-bold italic text-white dark:bg-gray-300 dark:text-gray-700">
          PRO
        </div>
      </div>
    </>
  ) : (
    <div className="absolute bottom-0 right-2 top-0 flex h-full items-center">
      <div className="h-4 w-4 rounded-full bg-gray-600 text-center text-2xs font-bold italic text-white dark:bg-gray-300 dark:text-gray-700">
        P
      </div>
    </div>
  );
};
