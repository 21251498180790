export const faRaceHelmet = {
  prefix: 'fas',
  iconName: 'RaceHelmet',
  icon: [
    512,
    512,
    [127760],
    'f0ac',
    `
    M181.389 231.243C178.443 249.746 190.896 267.175 209.179 270.085C227.467 272.995 244.709 260.288 247.65 241.785C250.606 223.207 238.154 205.778 219.869 202.868C201.582 199.962 184.345 212.665 181.389 231.243Z
    M38.5553 110.616L40.5806 107.441C78.9427 47.4158 141.267 8.97304 211.501 1.96844C214.796 1.63358 218.028 1.38291 221.298 1.19844C335.223 -5.79424 430.473 55.9471 469.622 161.933C471.832 167.448 474.183 174.966 476.587 184.358C477.393 187.268 478.113 190.374 478.908 193.639C478.964 193.949 479.068 194.224 479.118 194.534C491.704 248.444 493.4 305.929 484.033 361.916C483.509 365.221 482.923 368.577 482.298 371.868C481.637 375.358 480.928 378.895 480.167 382.365L467.72 428.139C460.964 452.788 436.609 468.361 411.619 464.381C410.88 464.266 410.135 464.146 409.404 463.981L56.9189 391.694C37.2052 387.649 21.9271 372.301 17.9627 352.632L5.16104 289.577C-7.39543 227.525 4.44117 163.929 38.5521 110.62L38.5553 110.616ZM454.861 354.644C463.132 302.995 461.358 250.148 449.706 200.543C449.042 197.703 448.4 195.067 447.815 192.697C446.785 188.68 445.845 185.346 444.975 182.525L215.969 169.278C196.559 169.027 178.246 177.2 165.46 191.728C152.422 206.515 146.556 226.193 149.273 245.711C152.863 271.501 171.187 292.896 195.898 300.221L453.134 364.546C453.759 361.255 454.339 357.955 454.865 354.643L454.861 354.644Z
    `,
  ],
};
