import { createContext, ReactNode, useContext, useState } from 'react';
import config from '~/config';

export type Theme = 'light' | 'system' | 'dark';

const getIsDarkMode = (selectedTheme?: Theme) => {
  switch (selectedTheme) {
    case 'system':
      return matchMedia('(prefers-color-scheme: dark)').matches;
    case 'light':
      return false;
    case 'dark':
      return true;
    default:
      return true;
  }
};

const getThemeFromLocalStorage = config.get('interface.theme') ?? 'system';

const ThemeContext = createContext({
  isDarkMode: getIsDarkMode(getThemeFromLocalStorage),
  theme: getThemeFromLocalStorage,
  toggleTheme: (_selectedTheme?: Theme) => {},
});

export const ThemeContextProvider = ({ children }: { children: ReactNode }) => {
  const [themeState, setThemeState] = useState(getThemeFromLocalStorage);

  const setTheme = (theme: Theme) => {
    const darkOrLight = getIsDarkMode(theme) ? 'dark' : 'light';
    document.documentElement.classList.remove(
      darkOrLight === 'dark' ? 'light' : 'dark'
    );
    document.documentElement.classList.add(darkOrLight);
    document.documentElement.setAttribute('data-theme', darkOrLight);
    document.documentElement.style.setProperty('color-scheme', darkOrLight);

    config.set('interface.theme', theme);
    setThemeState(theme);
  };

  const isDarkMode = getIsDarkMode(themeState);

  return (
    <ThemeContext.Provider
      value={{
        isDarkMode,
        toggleTheme: (theme) => {
          setTheme(theme ?? (isDarkMode ? 'light' : 'dark'));
        },
        theme: themeState,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => {
  return useContext(ThemeContext);
};
