import { authExchange } from '@urql/exchange-auth';
import { Client as WSClient, createClient as createWSClient } from 'graphql-ws';
import {
  Client as URQLClient,
  cacheExchange,
  createClient,
  subscriptionExchange,
} from 'urql';
import config from '../config';
import { logger } from '../features/common/utils/logger';
import { queryClient } from '../graphql/queryClient';
import { getUrl } from './getUrl';

let wsClient: WSClient | undefined;
export let urqlClient: URQLClient;

let wasDisconnected = false;

const connect = () => {
  const { ws } = getUrl();

  wsClient = createWSClient({
    url: ws,
    connectionParams: {
      authorization: config.get('security.jwt') || null,
    },
    keepAlive: 10_000, // ping server every 10 seconds
    on: {
      connected: (socket) => {
        logger.log('subscription connected successfully');
        if (wasDisconnected) {
          queryClient.invalidateQueries();
        }
      },
      closed: (event) => {
        logger.log('subscription disconnected');
        wasDisconnected = true;
      },
    },
    shouldRetry: () => true,
    retryAttempts: Infinity,

    retryWait: (nr) => {
      return new Promise((resolve) => {
        const timeoutNow = Math.min((nr + 1) * (nr + 1) * 1000, 60000);
        logger.log(
          `subscription connection failed, retrying in ${
            timeoutNow / 1000
          } seconds`
        );
        setTimeout(resolve, timeoutNow);
      });
    },
  });
};

connect();
urqlClient = createClient({
  ...getClientParameters(),
  fetchSubscriptions: false,
});

function getClientParameters() {
  const { http } = getUrl();
  return {
    url: http,
    exchanges: [
      // debugExchange,
      cacheExchange,
      authExchange(async (utils) => {
        const token = config.get('security.jwt') || null;
        return {
          addAuthToOperation(operation) {
            return utils.appendHeaders(operation, {
              Authorization: token,
            });
          },
          willAuthError() {
            return false;
          },
          didAuthError(error) {
            return error.graphQLErrors.some(
              (error) => error.extensions?.code === 'FORBIDDEN'
            );
          },
          async refreshAuth() {},
        };
      }),
      subscriptionExchange({
        forwardSubscription: (request) => {
          const input = { ...request, query: request.query || '' };
          return {
            subscribe: (sink) => ({
              unsubscribe:
                wsClient?.subscribe(input, sink) ??
                (() => {
                  logger.error('wsClient is undefined');
                }),
            }),
          };
        },
      }),
    ],
  };
}

export function urqlReinitialize() {
  logger.log('reinitialising subscription client');
  connect();
  urqlClient = createClient(getClientParameters());
}
