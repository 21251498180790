import { matchPath, useLocation } from 'react-router-dom';
import {
  SideBarNavigation,
  SidebarChild,
} from './components/SidebarNavigationLinks';

export const isSelfOrSubItemOpen = (
  sidebarChild: SidebarChild | SideBarNavigation,
  pathname: string
): { isOpen: boolean; isSelfOpen: boolean; isChildOpen: boolean } => {
  const ownLink =
    'href' in sidebarChild ? sidebarChild.href : sidebarChild.link;

  const isSelfOpen = Boolean(ownLink && matchPath(ownLink, pathname));
  const isChildOpen = (sidebarChild.children ?? []).some(
    (child) => isSelfOrSubItemOpen(child, pathname).isOpen
  );
  const isOpen = isSelfOpen || isChildOpen;

  return { isOpen, isSelfOpen, isChildOpen };
};

export const useIsSelfOrSubItemOpen = (
  sidebarChild: SidebarChild | SideBarNavigation
) => {
  const { pathname, hash } = useLocation();
  return isSelfOrSubItemOpen(sidebarChild, pathname + hash);
};
