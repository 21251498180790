import { useThemeContext } from '../utils/services/HandleThemeToggling';

export const PaceteqLogo = ({
  classNames,
  collapsed,
  testId,
}: {
  classNames: string;
  collapsed: boolean;
  testId: string;
}) => {
  const { isDarkMode } = useThemeContext();

  return (
    <div className={classNames} data-testid={testId}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox={collapsed ? '0 0 500 500' : '0 0 2200 500'}
        fill={isDarkMode ? '#FFFFFF' : '#000000'}
      >
        {!collapsed && (
          <>
            <g>
              <path
                d="M693.85632,124.15967h-88.08148c-1.56555,0-2.83466,1.26929-2.83466,2.83472v253.99048
		c0,1.56567,1.2691,2.83472,2.83466,2.83472h48.51068c1.56555,0,2.83466-1.26904,2.83466-2.83472
		c0-0.00464-0.00134-83.59473-0.00134-83.59473h36.73749c58.26056,0,96.11139-32.64307,96.11139-84.94604
		C789.96771,159.39941,752.11688,124.15967,693.85632,124.15967z M690.51654,250.65137h-33.39771v-76.7854h33.39771
		c26.71826,0,45.27258,11.12842,45.27258,38.57812C735.78912,240.26489,717.2348,250.65137,690.51654,250.65137z"
              />
              <path
                d="M1259.87976,320.20532c-0.5199-0.60522-1.28137-0.99658-2.14185-0.99658c-0.61462,0-1.18237,0.18979-1.68982,0.55615
		c-13.88464,10.23242-32.25671,16.94507-50.9646,16.94507c-46.75684,0-82.01001-34.49756-82.01001-82.72021
		s35.25317-82.72021,82.01001-82.72021c17.99109,0,35.64685,6.36914,49.14172,15.28345
		c0.45776,0.31895,1.00854,0.49878,1.60339,0.49878c0.83118,0,1.57153-0.36426,2.09009-0.93433l29.15649-32.79199
		c0.41577-0.49316,0.67603-1.12183,0.67603-1.81738c0-0.84253-0.37451-1.60027-1.00391-2.16333
		c-22.04993-18.47925-51.60474-28.89429-84.63257-28.89429c-72.73279,0-135.44641,54.52856-135.44641,133.53931
		s62.71362,133.53931,135.44641,133.53931c33.45251,0,63.42285-10.12253,86.18433-29.66183
		c0.63098-0.58084,0.99646-1.32205,0.99646-2.15457c0-0.70435-0.26624-1.34033-0.69128-1.83594L1259.87976,320.20532z"
              />
              <path
                d="M944.49878,125.81885c-0.44702-0.97803-1.4281-1.66089-2.57373-1.66089c-0.00641,0-45.28748,0-45.29388,0
		c-1.14594,0-2.12738,0.68335-2.57416,1.66162L781.15759,379.82397c-0.16003,0.35522-0.25397,0.74609-0.25397,1.16089
		c0,1.56567,1.26917,2.83472,2.83466,2.83472h51.27954c1.15948,0,2.1532-0.69824,2.59229-1.69531l18.95331-44.67261h125.42712
		l18.97797,44.71851c0.44965,0.97144,1.42651,1.64941,2.56732,1.64941h51.64215h0.00012c1.56543,0,2.83459-1.26904,2.83459-2.83472
		c0-0.41968-0.09692-0.8147-0.26062-1.1731L944.49878,125.81885z M876.36566,291.25195l42.91125-100.69336l42.91132,100.69336
		H876.36566z"
              />
              <path
                d="M2136.15063,357.48291c29.68701-23.74048,50.46777-60.09277,50.46777-103.49316
		c0-78.26904-67.5376-133.53931-131.36426-133.53931c-63.8269,0-130.99365,55.27026-130.99365,133.53931
		s67.16675,133.53931,130.99365,133.53931c25.23389,0,51.20996-8.53149,73.104-24.11108l16.61255,19.41504
		c0.51978,0.59961,1.27783,0.98682,2.13354,0.98682h7.32617c1.10303,0,1.99731-0.89429,1.99731-1.99731
		c0-0.47144-0.16992-0.89868-0.44287-1.24023L2136.15063,357.48291z M2081.78442,294.29248
		c-0.51953-0.59668-1.27563-0.98145-2.12891-0.98169h-7.30029c-1.10303,0.00049-1.99683,0.89453-1.99683,1.99731
		c0,0.50024,0.19019,0.9519,0.49414,1.30225l50.45483,58.64673c-20.03857,13.72485-43.41699,21.1438-66.05322,21.1438
		c-57.14746,0-120.23193-49.7063-120.23193-122.41113s63.08447-122.41113,120.23193-122.41113
		c57.51807,0,120.60303,49.7063,120.60303,122.41113c0,40.43286-19.66748,73.81763-46.75684,95.33228L2081.78442,294.29248z"
              />
              <path
                d="M1716.92603,124.15967c-0.0011,0-162.05945,0-162.06152,0c-1.56555,0-2.83459,1.26929-2.83459,2.83472v5.0874
		c0,1.56567,1.26904,2.83472,2.83459,2.83472h75.83569l0.0011,246.0686c0,1.56543,1.26904,2.83472,2.83459,2.83472h5.09106
		c1.56555,0,2.83459-1.26904,2.83459-2.83472l0.00012-246.0686h75.46509c1.56519-0.00049,2.83398-1.26929,2.83398-2.83472v-5.0874
		C1719.76074,125.42896,1718.49158,124.15967,1716.92603,124.15967z"
              />
              <path
                d="M1900.87256,373.06323l-133.2417-0.00003V258.81049h113.39819c1.55664-0.0105,2.81555-1.27313,2.8158-2.83221
		l-0.00012-5.08887c-0.00049-1.56519-1.26929-2.83371-2.83447-2.83371h-113.37939V134.9165h128.90063
		c1.56555,0,2.83459-1.26904,2.83472-2.83447v-5.08765c0-1.56152-1.26282-2.82874-2.82275-2.83533
		c0,0-136.83752-0.00005-136.83948-0.00005c-1.56555,0-2.83459,1.26996-2.83459,2.83539v253.99072
		c0,1.56543,1.26904,2.83472,2.83459,2.83472h141.1676c1.56555,0,2.83472-1.26929,2.83472-2.83472v-5.0874
		C1903.7063,374.33228,1902.43774,373.06372,1900.87256,373.06323z"
              />
              <path
                d="M1496.87195,334.11209c-0.00354,0-107.00671,0-107.00671,0v-56.3833h90.87317
		c1.56201-0.00073,2.8291-1.26492,2.83398-2.82596c0,0-0.00061-43.30176-0.00061-43.30225
		c0-1.56519-1.26892-2.83423-2.83411-2.83447l-90.87244-0.00002v-54.8987c0,0,103.66296,0,103.6676,0
		c1.55713,0,2.81873-1.25632,2.83215-2.81027c0,0,0.00024-44.06079,0.00024-44.06274c0-1.5542-1.24951-2.81727-2.79944-2.83533
		c0,0-155.03882,0.00062-155.04468,0.00062c-1.56555,0-2.83459,1.26929-2.83459,2.83472v253.99207
		c0,1.56567,1.26904,2.83313,2.83459,2.83313l158.37903-0.00269c1.55212-0.01538,2.80652-1.27588,2.80652-2.83179v-44.03833
		C1499.70667,335.38135,1498.4375,334.11209,1496.87195,334.11209z"
              />
            </g>{' '}
          </>
        )}

        <path
          d="M232.88013,294.28931L125.62531,232.3855c-1.35028-0.7793-3.55988-0.7793-4.91016,0L13.45508,294.28931
	C12.10486,295.0686,11,296.98193,11,298.54102v123.7959c0,1.55908,1.10486,3.47241,2.45508,4.25171l107.26007,61.90381
	c1.35028,0.7793,3.55988,0.7793,4.91016,0l107.25482-61.90381c1.35028-0.77954,2.45508-2.69263,2.45508-4.25171v-123.7959
	C235.33521,296.98193,234.23041,295.0686,232.88013,294.28931z M187.52319,326.13403v68.60986v2.83472l-2.45465,1.4165
	c-0.00012,0.00024-0.00031,0.00024-0.00043,0.00024l-59.44275,34.30591c-0.00012,0-0.00031,0.00024-0.00043,0.00024l-2.45471,1.4165
	l-11.47864-6.62402l-50.42426-29.09863c-0.00024,0-0.00024,0-0.00024,0l-2.45508-1.41675v-2.83472v-68.60986v-2.83472
	l64.35822-37.14478l32.17651,18.57251l29.72144,17.15527c0.00006,0,0.00006,0,0.00012,0l2.4549,1.41699V326.13403z"
        />
        <path
          d="M360.98932,72.4834L253.72943,10.58447c-1.35034-0.7793-3.55994-0.7793-4.91022,0L141.55927,72.48828
	c-1.35028,0.7793-2.45508,2.69263-2.45508,4.25171v123.80103c0,1.55884,1.1048,3.47217,2.45508,4.25146l107.25989,61.90381
	c1.35028,0.7793,3.55988,0.7793,4.91022,0l107.26001-61.90381c1.35028-0.7793,2.45508-2.69263,2.45508-4.25146V76.73486
	C363.44446,75.17578,362.33966,73.2627,360.98932,72.4834z M315.63232,104.33301v68.60986v2.83472l-2.45483,1.41675
	c-0.00006,0-0.00018,0-0.00024,0.00024l-29.72394,17.15527l-32.17902,18.57227l-32.17651-18.57227l-29.7215-17.15527
	c-0.00006,0-0.00006,0-0.00012,0l-2.4549-1.41699v-2.83472v-68.60986v-2.83472l2.4549-1.41699c0.00006,0,0.00006,0,0.00012,0
	l29.7215-17.15527l32.17651-18.57227l2.45477,1.41675c0.00012,0,0.00018,0,0.00031,0l59.44788,34.31079
	c0.00006,0,0.00018,0,0.00024,0.00024l2.45483,1.41675V104.33301z"
        />
        <path
          d="M489.09338,294.28931L381.83856,232.3855c-1.35028-0.7793-3.55988-0.7793-4.91022,0l-107.26495,61.90381
	c-1.35034,0.7793-2.45514,2.69263-2.45514,4.25171v123.7959c0,1.55908,1.1048,3.47241,2.45508,4.25171l107.25989,61.90381
	c1.35028,0.7793,3.55988,0.7793,4.91016,0l107.26001-61.90381c1.35028-0.7793,2.45508-2.69263,2.45508-4.25171v-123.7959
	C491.54846,296.98193,490.44366,295.0686,489.09338,294.28931z M443.73651,326.13403v68.60986v2.83472l-2.45459,1.4165
	c-0.00018,0-0.00037,0.00024-0.00055,0.00024l-59.44775,34.30591c-0.00006,0.00024-0.00024,0.00024-0.00031,0.00024l-2.4549,1.4165
	l-9.86053-5.69019l-52.04236-30.03247c-0.00006,0-0.00018,0-0.00024,0l-2.45496-1.41675v-2.83472v-68.60986v-2.83472
	l64.36316-37.14478L411.56,304.72705l29.7215,17.15527c0.00006,0,0.00006,0,0.00012,0l2.4549,1.41699V326.13403z"
        />
      </svg>
    </div>
  );
};
