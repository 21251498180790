export const useGetCurrentServer = (serverAddress: string) => {
  return simpleServerAdress(serverAddress);
};

export const simpleServerAdress = (serverAddress: string) => {
  return serverAddress
    .toLowerCase()
    .replace('https://', '')
    .replace('http://', '')
    .replace('.oneracehub.com', '')
    .replace('.oneracehub.net', '')
    .replace(':443', '')
    .replace('/graphql', '')
    .replace('relay-orh-api-dev.rfkracing.com', 'rfk-dev')
    .replace('relay-orh-api.rfkracing.com', 'rfk-prod');
};
