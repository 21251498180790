import clsx from 'clsx';
import { useState } from 'react';
import { ProLabel } from '~/features/common/components/Sidebar/components/ProLabel';
import DropDownIcon from '~/features/common/components/icons/DropDownIcon/index';
import { useAuth } from '~/features/common/context/AuthContext';
import { hasRequiredRoles } from '../../../utils/loginChecks';
import SidebarItem from './SidebarItem';
import { SideBarCategory } from './SidebarNavigationLinks';

const NavCategory = ({
  collapsed,
  item,
  isHelpEnabled,
}: {
  collapsed: boolean;
  isHelpEnabled: boolean;
  item: SideBarCategory;
}) => {
  const [isFolded, setIsFolded] = useState(false);
  const { isProUser } = useAuth();

  return (
    <>
      <div
        className={clsx(
          collapsed && 'border-t-2 border-gray-300 dark:border-gray-600'
        )}
      >
        {!collapsed && (
          <div
            className="flex items-center gap-x-1 border-t border-gray-200 py-1 pl-4 uppercase text-gray-600 hover:cursor-pointer dark:border-gray-600  dark:text-gray-100"
            onClick={() => setIsFolded(!isFolded)}
          >
            <DropDownIcon className="w-4" opened={!isFolded}>
              <div className="relative w-full">
                <span
                  className={clsx(
                    'pl-3 text-sm font-medium',
                    item.availableInLightVersion || isProUser
                      ? 'text-gray-700 dark:text-gray-200'
                      : 'text-gray-200 dark:text-gray-500'
                  )}
                >
                  {item.name}
                </span>
                {!item.availableInLightVersion && (
                  <ProLabel collapsed={collapsed} />
                )}
              </div>
            </DropDownIcon>
          </div>
        )}

        {collapsed
          ? item?.children?.map(
              (itemChild, index) =>
                hasRequiredRoles(itemChild.restrictToRoles) && (
                  <SidebarItem
                    key={itemChild.name ?? index}
                    item={itemChild}
                    collapsed={collapsed}
                    isHelpEnabled={isHelpEnabled}
                  />
                )
            )
          : !isFolded &&
            item?.children?.map(
              (itemChild, index) =>
                hasRequiredRoles(itemChild.restrictToRoles) && (
                  <SidebarItem
                    key={itemChild.name ?? index}
                    item={itemChild}
                    collapsed={collapsed}
                    isHelpEnabled={isHelpEnabled}
                  />
                )
            )}
      </div>
    </>
  );
};

export default NavCategory;
