import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLoginPath } from '~/features/auth/routes';
import { Maybe } from '~/features/common/types/types';
import { queryClient } from '~/graphql/queryClient';
import config from '../../../config';
import { AuthInterface } from '../../common/context/AuthContext';

function useProvideAuth(): AuthInterface {
  const navigate = useNavigate();
  const [user, setUser] = useState<Maybe<string>>(() =>
    config.get('security.jwt')
  );

  function signin(token: string, cb: () => void) {
    config.set('security.jwt', token);
    setUser(token);
    setTimeout(cb, 100);
  }

  function signout() {
    const jwt = config.get('security.jwt');
    if (jwt !== '') {
      config.set('security.jwt', '');
    }
    queryClient.cancelQueries();
    queryClient.clear();
    setUser(null);

    setTimeout(() => {
      navigate(getLoginPath({}));
    }, 100);
  }

  config.onDidChange('security.jwt', () => {
    const jwt = config.get('security.jwt');
    if (jwt === '' || jwt == null) {
      signout();
    }
  });

  return {
    user,
    isLoggedIn: !!user,
    signin,
    signout,
  };
}

export default useProvideAuth;
