import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { ProLabel } from '~/features/common/components/Sidebar/components/ProLabel';
import { useIsSelfOrSubItemOpen } from '~/features/common/components/Sidebar/isSelfOrSubItemOpen';
import { useAuth } from '~/features/common/context/AuthContext';
import { useJobListForAssignee } from '~/features/common/utils/useJobListForAssignee';
import { useWhoAmIQuery } from '~/generated/graphql';
import HoverMenuList from './HoverMenuList';
import SideBarItemButton from './SidebarItemButton';
import {
  SideBarNavigation,
  SidebarChild,
  useAvailableMenuChildren,
} from './SidebarNavigationLinks';
import SidebarSubItem from './SidebarSubItem';

const SidebarItem = ({
  item,
  collapsed,
  isHelpEnabled,
}: {
  item: SideBarNavigation;
  collapsed: boolean;
  isHelpEnabled: boolean;
}) => {
  const meQuery = useWhoAmIQuery();
  const { isProUser } = useAuth();
  const menuItemRef = useRef<HTMLDivElement>(null);
  const [isHovering, setIsHovering] = useState(false);
  const availableChildren = useAvailableMenuChildren(item.children);

  const assigneeID = meQuery.data?.whoami?.claims.sub;
  const { jobs } = useJobListForAssignee(assigneeID);

  const openJobs = jobs.filter((job) => !job.closedAt).length;
  const { isOpen } = useIsSelfOrSubItemOpen(item);

  const showChildren =
    item.isactive &&
    isHovering &&
    (item.openChildrenWithHoverOnly ||
      collapsed ||
      (!collapsed && !isEmpty(availableChildren) && !isOpen));
  return (
    <Menu>
      <div
        ref={menuItemRef}
        className={clsx(
          isOpen
            ? 'border-primary dark:border-blue-400 dark:text-blue-400'
            : 'border-transparent',
          'border-l-4',
          'relative'
        )}
        onMouseEnter={() =>
          item.availableInLightVersion || isProUser ? setIsHovering(true) : null
        }
        onMouseLeave={() => setIsHovering(false)}
      >
        <div
          className={clsx(
            // Active, Light Version, and Open
            item.isactive &&
              (item.availableInLightVersion || isProUser) &&
              isOpen &&
              'bg-gray-50 text-gray-900 hover:bg-gray-100 dark:bg-gray-800 dark:text-gray-100 dark:hover:bg-gray-900',

            // Active, Light Version, and NOT Open
            item.isactive &&
              !isOpen &&
              (item.availableInLightVersion || isProUser) &&
              'text-gray-600 hover:bg-gray-100 hover:text-gray-900 dark:text-gray-200 dark:hover:bg-gray-800 dark:hover:text-gray-100',

            // NOT Active
            !item.isactive && 'text-gray-300 dark:text-gray-600',

            !item.availableInLightVersion &&
              !isProUser &&
              'text-gray-300 dark:text-gray-600',

            // Default
            'group w-full px-2 py-1 text-sm',

            // Hovering
            item.isactive &&
              isHovering &&
              'bg-gray-50 dark:bg-gray-800 dark:text-gray-100',

            // Collapsed
            collapsed && 'py-0 pr-3'
          )}
        >
          <SideBarItemButton
            collapsed={collapsed}
            item={item as SideBarNavigation}
            openJobs={openJobs}
          />
          {!item.availableInLightVersion && <ProLabel collapsed={collapsed} />}
          {showChildren && (
            <HoverMenuList
              topPosition={menuItemRef.current?.getBoundingClientRect().top}
              item={item}
              collapsed={collapsed}
              childLevel="direct"
              onMenuItemClicked={() => setIsHovering(false)}
            />
          )}
        </div>
        {isOpen &&
          !collapsed &&
          !item.openChildrenWithHoverOnly &&
          availableChildren?.map((child: SidebarChild, index) => (
            <SidebarSubItem
              collapsed={collapsed}
              child={child}
              key={child.link ?? index}
              isHelpEnabled={isHelpEnabled}
            />
          ))}
      </div>
    </Menu>
  );
};

export default SidebarItem;
