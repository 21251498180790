import { faWarning } from '@fortawesome/free-solid-svg-icons';
import WarningMessage from '~/features/common/components/WarningMessage/WarningMessage';
import Icon from '~/features/common/components/icons/Icon/index';
import { useRequiredClientVersionQuery } from '~/generated/graphql';
import packagejson from '../../../../../package.json';

const ClientVersionCheck = ({ collapsed }: { collapsed: boolean }) => {
  const versionQuery = useRequiredClientVersionQuery();
  const requiredClientVersion = versionQuery.data?.requiredClientVersion;
  if (versionQuery.isLoading) {
    return <></>;
  }

  if (!requiredClientVersion) {
    return <></>;
  }

  const reqSplit = requiredClientVersion.split('.');
  const versionSplit = packagejson.version.split('.');
  const warningMessageText = `Client Version Mismatch! <br />
<strong>Required: ${requiredClientVersion} </strong><br />
Current: ${packagejson.version}`;
  if (
    parseFloat(versionSplit[0]) < parseFloat(reqSplit[0]) ||
    parseFloat(versionSplit[1]) < parseFloat(reqSplit[1])
  ) {
    return !collapsed ? (
      <WarningMessage classNames="my-2 mx-1" level="yellow" textSize="text-xs">
        <span>
          Client Version Mismatch! <br />
          <strong>Minimum required: {requiredClientVersion} </strong>
          <br />
          Current: {packagejson.version}
          <br />
          Please download from "https://www.paceteq.com/downloads"!
        </span>
      </WarningMessage>
    ) : (
      <span className="my-3 flex justify-center text-yellow-500">
        <Icon html={warningMessageText} icon={faWarning} />
      </span>
    );
  }
  return <></>;
};

export default ClientVersionCheck;
