import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeContextProvider } from '~/features/common/utils/services/HandleThemeToggling';
import { SelectionContextProvider } from './features/common/context/SelectionContext';

type Props = {
  children: ReactNode;
};

export default function App(props: Props) {
  const { children } = props;
  return (
    <SelectionContextProvider>
      <ThemeContextProvider>
        <div className="is-clipped">{children}</div>
        <ToastContainer closeOnClick={true} />
      </ThemeContextProvider>
    </SelectionContextProvider>
  );
}
