import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { Dispatch, SetStateAction } from 'react';
import Icon from '~/features/common/components/icons/Icon/index';

const SidebarCollapseButton = ({
  collapsed,
  setCollapsed,
}: {
  collapsed: boolean;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <div className="flex flex-shrink-0 border-t border-gray-300 px-4 py-3 dark:border-gray-600">
      <button
        type="button"
        onClick={() => {
          setCollapsed(!collapsed);
        }}
        className="group block w-full flex-shrink-0 focus:outline-none"
      >
        <div className="flex items-center pl-0.5">
          {collapsed ? (
            <div className="h-6 text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-50">
              <Icon icon={faChevronRight} aria-hidden="true" size="lg" />
            </div>
          ) : (
            <div className="flex items-center">
              <div className="mr-3 flex h-6 items-center text-gray-400 group-hover:text-gray-500 dark:group-hover:text-gray-50">
                <Icon icon={faChevronLeft} aria-hidden="true" size="lg" />
              </div>
              <div className="ml-1.5 text-sm font-medium text-gray-700 group-hover:text-gray-900 dark:text-gray-200 dark:group-hover:text-gray-50">
                Collapse
              </div>
            </div>
          )}
        </div>
      </button>
    </div>
  );
};
export default SidebarCollapseButton;
