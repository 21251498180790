import {
  faCaretRight,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import CustomTooltipWrapper from '~/features/common/components/CustomTooltipWrapper';
import { useIsSelfOrSubItemOpen } from '~/features/common/components/Sidebar/isSelfOrSubItemOpen';
import Icon from '~/features/common/components/icons/Icon/index';
import { useAuth } from '~/features/common/context/AuthContext';
import { cn } from '~/features/common/utils/tailwind';
import HoverMenuList from './HoverMenuList';
import { ProLabel } from './ProLabel';
import { SidebarChild } from './SidebarNavigationLinks';

const SidebarSubItem = ({
  child,
  collapsed,
  isSubFromSub,
  isHelpEnabled,
}: {
  child: SidebarChild;
  collapsed: boolean;
  isSubFromSub?: boolean;
  isHelpEnabled: boolean;
}) => {
  const { isProUser } = useAuth();
  const location = useLocation();
  const subItemRef = useRef<HTMLDivElement>(null);
  const { isOpen, isSelfOpen } = useIsSelfOrSubItemOpen(child);
  const [isHovering, setIsHovering] = useState(false);
  const showChildren = !isOpen && child.children && isHovering;
  const getLink = () => {
    if (isProUser) {
      return child.link ?? '';
    }
    return location.pathname;
  };
  return (
    <div ref={subItemRef}>
      <div
        className={cn('flex items-center', {
          'hover:bg-gray-150 text-gray-900 hover:text-gray-900 dark:text-gray-50 dark:hover:text-gray-100':
            child.isactive && (child.availableInLightVersion || isProUser),
          'text-gray-200 hover:bg-white dark:text-gray-600 dark:hover:bg-gray-900':
            !(child.isactive && (child.availableInLightVersion || isProUser)),
          'bg-gray-50 hover:bg-gray-100 dark:bg-gray-800 dark:hover:bg-gray-800':
            isOpen,
          'hover:bg-gray-50 dark:hover:bg-gray-700': !isOpen,
        })}
      >
        <Link
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          className={cn(
            'group flex grow items-center bg-transparent py-2 text-sm text-inherit',
            isSubFromSub ? 'pl-14' : 'pl-11'
          )}
          key={child.name}
          data-testid={child.name.toLowerCase() + '-sidebar-navigation'}
          to={getLink()}
        >
          {isSelfOpen && (
            <Icon
              icon={faCaretRight}
              size="2x"
              className={clsx(
                'relative h-[18px] text-primary dark:text-blue-400',
                isSubFromSub ? 'right-[60px]' : ' right-[48px]'
              )}
            />
          )}
          <div
            key={child.name}
            className={clsx(
              isSelfOpen && 'relative right-[9px]',
              'relative flex w-full justify-between'
            )}
          >
            <>
              {child.name}
              {!child.availableInLightVersion && (
                <ProLabel collapsed={collapsed} />
              )}
            </>
          </div>
          {showChildren && (
            <HoverMenuList
              topPosition={subItemRef.current?.getBoundingClientRect().top}
              item={child}
              childLevel={'direct'}
              collapsed={collapsed}
              onMenuItemClicked={() => setIsHovering(false)}
            />
          )}
        </Link>
        {isHelpEnabled && child.helpLink && (
          <CustomTooltipWrapper
            positionStrategy="fixed"
            content="Click to open knowledge database in browser"
          >
            <Link
              target="_blank"
              to={child.helpLink}
              className="p-2 text-primary dark:text-blue-100"
              title="Click to open knowledge database in browser"
            >
              <Icon icon={faCircleQuestion} size="sm" />
            </Link>
          </CustomTooltipWrapper>
        )}
      </div>
      {isOpen &&
        child.children &&
        child.children?.map((subChild) => (
          <SidebarSubItem
            collapsed={collapsed}
            child={subChild}
            key={subChild.link}
            isSubFromSub
            isHelpEnabled={isHelpEnabled}
          />
        ))}
    </div>
  );
};

export default SidebarSubItem;
