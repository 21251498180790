import { useContext } from 'react';
import { SessionTimeRemaining } from '~/features/protocol/runsheets/components/SessionTimeRemaining';
import SelectionContext from '../../../context/SelectionContext';

export const TimeRemaining = () => {
  const { selection } = useContext(SelectionContext);
  return (
    <div className="mt-4">
      <SessionTimeRemaining sessionID={selection.sessionID ?? ''} />
    </div>
  );
};
