import { groupBy } from 'lodash';
import {
  ActiveConfig,
  FailedConfig,
  GetTimingServiceStatusQuery,
} from '../../testing/utils/generated/graphql';

// Mock for testing
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const timingStatusQuery = {
  activeConfigs: [
    {
      declaredSession:
        '"DeclareTime=2024-04-14 10:34:28+0200,Series=N24,SessionName=Top Qualifying,SessionType=QUALIFYING,StartTime=2024-04-14 10:40:00+02:00,EndTime=2024-04-14 11:40:00+02:00,EventName=(01) ADAC 24h Nürburgring Qualifiers,SectorsCount=9,Synced=True",',
      lastMessage: '2022-08-12T08:14:25.869Z',
      timingServiceConfigID: '6618f24e10098e97dd24baf1',
      sessionMatchInfo: '',
      sessionMatchID: 'lololoolol',
      timingServiceConfig: {
        _id: '6618f24e10098e97dd24baf1',
        active: true,
        name: 'Top Qualifying',
      },
    },
    {
      declaredSession:
        '"DeclareTime=2024-04-14 10:34:28+0200,Series=N24,SessionName=Top Qualifying,SessionType=QUALIFYING,StartTime=2024-04-14 10:40:00+02:00,EndTime=2024-04-14 11:40:00+02:00,EventName=(01) ADAC 24h Nürburgring Qualifiers,SectorsCount=9,Synced=True",',
      lastMessage: '2022-08-12T08:12:11.869Z',
      timingServiceConfigID: '02000000000000000eeee001',
      sessionMatchInfo: '',
      sessionMatchID: '',
      timingServiceConfig: {
        _id: '02000000000000000eeee001',
        active: true,
        name: 'Top Qualifying',
      },
    },
  ],
  failedConfigs: [
    {
      message:
        'Stream Handler [bad-url.io:43210] error: Failed to connect after 3 attempts. Stopping...',
      timingServiceConfigID: '661931a8f743871e1163a91b',
      timingServiceConfig: {
        _id: '661931a8f743871e1163a91b',
        active: true,
        name: 'Evs',
      },
    },
  ],
};

export type GroupedTimingStatus = {
  [timingServiceConfigID: string]:
    | (ActiveConfig & { failed: boolean })
    | (FailedConfig & { failed: boolean });
};

// Here we combine the active and failed services into a single object
export const groupTimingServiceStatusUpdates = (
  data: GetTimingServiceStatusQuery | undefined
): GroupedTimingStatus | undefined => {
  if (!data) return undefined;

  // When assigning the object values the "failed" flag is added so that we can differentiate between the failed and active services
  const activeConfigs = groupBy(
    data.timingServiceStatus?.activeConfigs?.map((config) => ({
      ...config,
      failed: false,
    })),
    'timingServiceConfigID'
  );
  const failedConfigs = groupBy(
    data.timingServiceStatus?.failedConfigs?.map((config) => ({
      ...config,
      failed: true,
    })),
    'timingServiceConfigID'
  );

  const allTimingStatus = {
    ...activeConfigs,
    ...failedConfigs,
  };

  if (Object.keys(allTimingStatus).length < 1) return undefined;

  // KEY = timingServiceConfigID : VALUE = timingServiceStatus object (active or failed)
  let groupedTimingStatus = {};

  Object.keys(allTimingStatus).forEach((key) => {
    groupedTimingStatus = {
      ...groupedTimingStatus,
      [key]: allTimingStatus[key][0],
    };
  });

  return groupedTimingStatus;
};
