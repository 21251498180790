import { faCircle } from '@fortawesome/free-regular-svg-icons';
import clsx from 'clsx';
import { add, isAfter, set } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/features/common/context/AuthContext';
import { getRole, hasRequiredRoles } from '~/features/common/utils/loginChecks';
import {
  EnumPersonRole,
  useGetSessionsInDateTimeRangeForTimingServiceStatusQuery,
} from '~/generated/graphql';
import { getIndexPath as getTimingServicePath } from '../../../../system/agents/timing-service/routes';
import useTimingServiceStatus from '../../../hooks/useTimingServiceStatus/useTimingServiceStatus';
import CustomTooltipWrapper from '../../CustomTooltipWrapper';
import Button from '../../buttons/Button';
import Icon from '../../icons/Icon';
import { getTimingServiceStatusForDisplay } from '../helpers/getTimingServiceStatusForDisplay';

export const TimingServiceStatusIndicator = (props: { collapsed: boolean }) => {
  const { collapsed } = props;
  const { timingStatus: status, error } = useTimingServiceStatus();

  const auth = useAuth();
  const navigate = useNavigate();
  getRole();

  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    return () => clearInterval(interval);
  }, [currentDate]);

  const startDate = set(add(currentDate, { days: -2 }), {
    hours: 0,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });

  const endDate = set(add(currentDate, { days: 1 }), {
    hours: 23,
    minutes: 59,
    seconds: 59,
    milliseconds: 0,
  });

  const { data } = useGetSessionsInDateTimeRangeForTimingServiceStatusQuery(
    {
      startDate: startDate,
      endDate: endDate,
    },
    {
      enabled: auth.isLoggedIn,
    }
  );
  const sessions = data?.sessions?.items || [];

  const upcomingSessions = sessions
    .filter((session) => isAfter(new Date(session.tSessionStart), currentDate))
    .sort(
      (s1, s2) =>
        new Date(s1.tSessionStart).valueOf() -
        new Date(s2.tSessionStart).valueOf()
    );
  const upcomingSession = upcomingSessions[0];

  const timeBeforeSession =
    new Date(upcomingSession?.tSessionStart).getTime() - currentDate.getTime();

  const timingStatus = getTimingServiceStatusForDisplay(status);

  // stopped (all configs are stopped)
  // running (all configs are running, no failed config)
  // warning (some configs running and some configs failed)
  const statusStyles = {
    RUNNING: {
      badge: 'bg-green-500 dark:bg-green-500/30',
      circle: 'text-green-500',
      text: 'Running',
    },
    STOPPED: {
      badge: 'bg-red-500/90 dark:bg-red-500/50',
      circle: 'text-red-500',
      text: 'Stopped',
    },
    ERROR: {
      badge: 'bg-red-300/10 dark:bg-red-500/50',
      circle: 'text-red-500',
      text: 'Error',
    },
  };

  const hasTimingConnection = !error;

  const isAdmin = hasRequiredRoles([EnumPersonRole.ADMIN]);

  return (
    <>
      <button
        onClick={() => navigate(getTimingServicePath({}))}
        disabled={!isAdmin}
      >
        <CustomTooltipWrapper
          html={
            isAdmin
              ? '<span class="text-xs">Go to Service Controls</span>'
              : null
          }
        >
          <span
            className={clsx(
              'flex items-baseline gap-x-0.5',
              'hover:bg-gray-50 dark:hover:bg-gray-700',
              'text-3xs font-bold uppercase text-gray-600 dark:text-gray-100',
              'rounded-md ring-1 ring-inset ring-gray-300 dark:ring-gray-500',
              collapsed ? 'px-0' : 'pl-1'
            )}
          >
            {!collapsed && (
              <>
                <span className="h-full">
                  <Icon
                    size="xs"
                    className={clsx(
                      hasTimingConnection ? 'text-green-500' : 'text-red-500'
                    )}
                    icon={faCircle}
                  />
                </span>
                <span className="py-0.5">Timing</span>

                <>
                  <span
                    className={clsx(
                      'flex items-center self-stretch rounded-r-md px-1 text-white',
                      status && statusStyles[timingStatus]?.badge
                    )}
                  >
                    {status && statusStyles[timingStatus]?.text}
                  </span>

                  {!status && hasTimingConnection && (
                    <span
                      className={clsx(
                        'animate-loading flex items-center rounded-r-md px-1'
                      )}
                    >
                      Connecting
                    </span>
                  )}
                </>

                {!hasTimingConnection && !collapsed && (
                  <p className="pr-1">Disconnected</p>
                )}
              </>
            )}

            {collapsed && (
              <>
                <span className="flex items-center gap-x-1.5 px-2 py-1">
                  <Icon
                    className={clsx(
                      hasTimingConnection ? 'text-green-500' : 'text-red-500'
                    )}
                    icon={faCircle}
                  />
                  <Icon
                    style={
                      {
                        '--fa-animation-duration': '2.5s',
                      } as React.CSSProperties
                    }
                    className={clsx(
                      status
                        ? statusStyles[timingStatus]?.circle
                        : 'text-gray-300'
                    )}
                    fade={timingStatus === 'RUNNING'}
                    icon={faCircle}
                  />
                </span>
                <>
                  {!status && hasTimingConnection && (
                    <span className="animate-loading flex items-center rounded-r-md px-1">
                      Connecting
                    </span>
                  )}
                </>
              </>
            )}
          </span>
        </CustomTooltipWrapper>
      </button>

      {timeBeforeSession <= 600_000 &&
        timingStatus !== 'RUNNING' &&
        getRole() === EnumPersonRole.ADMIN && (
          <div className="mt-2 flex justify-center">
            <Button
              toolTip={`The session '${upcomingSession?.name}' is about to start. Do you want to go the timing service controls?`}
              data-testid="start-timing-service-button"
              id="start-timing-service-button"
              type="button"
              primary
              onClick={() => {
                navigate(getTimingServicePath({}));
              }}
            >
              Go To Service Controls
            </Button>
          </div>
        )}
    </>
  );
};
