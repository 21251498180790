import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { Portal } from '../../Portal';
import SidebarActions from './SidebarActions';
import { SideBarNavigation, SidebarChild } from './SidebarNavigationLinks';

const HoverMenuList = ({
  topPosition,
  item,
  onMenuItemClicked,
  collapsed,
  childLevel,
}: {
  topPosition?: number;
  item: SideBarNavigation | SidebarChild;
  collapsed: boolean;
  onMenuItemClicked: () => void;
  childLevel: 'direct' | 'sub';
}) => {
  const verticalPosition = calculateVerticalPosition(item, topPosition);

  return (
    <Portal>
      <div
        className={clsx(
          childLevel === 'direct'
            ? collapsed
              ? 'left-7'
              : 'left-[13.00rem]'
            : '',
          childLevel === 'sub'
            ? collapsed
              ? 'left-[15.5rem]'
              : 'left-[26.5rem]'
            : '',
          'left absolute z-50 origin-top-right dark:ring-1 dark:ring-gray-700'
        )}
        style={verticalPosition}
      >
        <Menu.Items
          static
          className="w-56 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-900"
        >
          <SidebarActions
            item={item}
            collapsed={collapsed}
            onMenuItemClicked={onMenuItemClicked}
            showParent={childLevel === 'direct'}
          />
        </Menu.Items>
      </div>
    </Portal>
  );
};

export default HoverMenuList;

function calculateVerticalPosition(
  item: SideBarNavigation | SidebarChild,
  topPosition: number | undefined
) {
  const height = window.innerHeight;
  const numberOfChildren = item?.children?.length ?? 0;

  const estimatedPortalHeight = 40 * numberOfChildren;
  const positionOfBottomLine = (topPosition ?? 0) + estimatedPortalHeight;

  if (positionOfBottomLine > height) {
    return {
      top: 'unset',
      bottom: '40px',
    };
  }

  return {
    top: topPosition,
  };
}
