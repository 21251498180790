import {
  getForgotPasswordPath,
  getLoginPath,
  getLogoutPath,
} from '~/features/auth/routes';
import { logger } from '~/features/common/utils/logger';
import packagejson from '../../package.json';
import config from '../config';
import { changeAPIAddress } from './changeAPIAddress';
import { getHttpUrl } from './getUrl';

const partialDataSupported = ['runSheet', 'runSheets', 'runSheetsMany'];

function isPartialDataSupported(data: Record<string, unknown>): boolean {
  return (
    Object.keys(data).length === 1 &&
    partialDataSupported.includes(Object.keys(data)[0])
  );
}

export const routeIsAllowed = (path: string) => {
  const allowedPathsForUnsignedUsers = [
    getLoginPath({}),
    getForgotPasswordPath({}),
    'resetpassword',
  ];

  const pathToCheck = path.includes('resetpassword') ? 'resetpassword' : path;
  !allowedPathsForUnsignedUsers.includes(pathToCheck);
  return allowedPathsForUnsignedUsers.includes(pathToCheck);
};

export function fetcher<TData extends Record<string, unknown>, TVariables>(
  query: string,
  variables?: TVariables
) {
  return async (): Promise<TData> => {
    const res = await fetch(getHttpUrl(), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: config.get('security.jwt') || null,
        'ORH-CLIENT-VERSION': packagejson.version,
      },
      body: JSON.stringify({ query, variables }),
    });

    const json = (await res.json()) as {
      data: TData;
      errors?: Array<{ message: string; path: unknown[] }>;
    };
    json.errors?.forEach(({ message }) => {
      if (
        message.startsWith(
          'Context creation failed: This server is offline, use'
        )
      ) {
        // switch to new API server if suggested
        const newTarget = message.match(/http.*\/graphql/i)?.[0];
        if (newTarget) {
          changeAPIAddress(newTarget);
        }
      } else if (
        message.startsWith('You must be logged in') &&
        !routeIsAllowed(document.location.hash.substring(1))
      ) {
        document.location.hash = getLogoutPath({});
        logger.info('Your login session has expired.');
        throw new Error(message);
      } else if (message.startsWith('getaddrinfo ENOTFOUND')) {
        // don't log timing service not being available
      } else {
        const queryString = query.replaceAll('\n', '');
        const variableString = JSON.stringify(variables);
        logger.error(
          `[GraphQL error]: ${message}, query: ${queryString}, variables: ${variableString}`
        );
        // allow partial data to be returned for supported type
        if (!json.data || !isPartialDataSupported(json.data)) {
          throw new Error(message);
        }
      }
    });
    return json.data;
  };
}
