import config from '../config';
import { queryClient } from './queryClient';
import { urqlReinitialize } from './urqlClient';

const nonSecure = /http:\/\//i;
const httpPrefix = /https{0,1}:\/\//i;
const graphqlSuffix = /\/graphql$/i;

export function changeAPIAddress(
  /**
   * Base part of the URI.
   * Prefixing `http(s)://` is optional, a default port of 8347 is used,
   * and appending `/graphql` is also optional.
   * */
  address: string,
  clearQueryCache: boolean = true
) {
  let toSave = address.replace(graphqlSuffix, '');
  if (!nonSecure.test(address)) toSave = toSave.replace(httpPrefix, '');
  config.set('api.address', toSave);

  if (clearQueryCache) {
    queryClient.cancelQueries();
    queryClient.clear();
  }

  urqlReinitialize();
}
