import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import Icon from '~/features/common/components/icons/Icon/index';
import { useAuth } from '~/features/common/context/AuthContext';
import { SideBarNavigation } from './SidebarNavigationLinks';

const SideBarItemButton = ({
  collapsed,
  item,
  openJobs,
}: {
  collapsed: boolean;
  item: SideBarNavigation;
  openJobs: number;
}) => {
  const navigate = useNavigate();
  const hasNotifications = item.name === 'Jobs' && !!openJobs;
  const { isProUser } = useAuth();

  return (
    <>
      <div className="relative flex w-full">
        <button
          type="button"
          data-testid={item.name.toLowerCase() + '-sidebar-navigation'}
          className={clsx(
            !item.isactive && 'cursor-default',
            collapsed && 'justify-center',
            'flex w-full items-center  focus:outline-none'
          )}
          onClick={
            (item.isactive && item.availableInLightVersion) || isProUser
              ? () => {
                  if (item.href) {
                    navigate(item.href);
                  } else if (item.action) {
                    item.action();
                  }
                }
              : undefined
          }
        >
          <div
            className={clsx(
              item.isactive && (item.availableInLightVersion || isProUser)
                ? 'text-gray-400 group-hover:text-gray-900 dark:text-gray-400 dark:group-hover:text-gray-300'
                : 'cursor-default text-gray-300  dark:text-gray-600',
              'h-6 w-6'
            )}
          >
            <Icon
              icon={item.icon}
              aria-hidden="true"
              size="sm"
              className="mt-1"
            />
          </div>
          {!collapsed && <span className="ml-3 text-left">{item.name}</span>}
          {hasNotifications && (
            <div
              className={clsx(
                collapsed ? 'absolute right-0 top-0 h-2 w-2 ' : 'h-4 w-4',
                'align ml-2 flex justify-center rounded-full bg-red-500 text-white'
              )}
            >
              {!collapsed && (
                <span className="text-center text-2xs">{openJobs}</span>
              )}
            </div>
          )}
        </button>
        {!collapsed &&
          item.isactive &&
          (item.availableInLightVersion || isProUser) &&
          item.children &&
          item.children?.length > 0 && (
            <div className="text-right">
              <Icon
                className="text-gray-400 dark:text-gray-100"
                icon={faChevronRight}
              />
            </div>
          )}
      </div>
    </>
  );
};

export default SideBarItemButton;
