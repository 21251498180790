import { ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  children: ReactNode;
}

export function Portal({ children }: PortalProps) {
  const [domNode, setDomNode] = useState<HTMLElement>();

  useEffect(() => {
    const node = document.createElement('div');

    document.body.appendChild(node);

    setDomNode(node);

    return () => {
      node.remove();
    };
  }, []);

  if (!domNode) {
    return null;
  }

  return ReactDOM.createPortal(children, domNode);
}
