import { createContext, ReactNode, useState } from 'react';
import config from '../../../config';

export interface Selection {
  raceSeriesID: string | null;
  seasonID: string | null;
  eventID: string | null;
  sessionID: string | null;
  contestantID: string | null;
  NRun: string | null;
  runSheetID: string | null;
}
export const defaultSelection: Selection = {
  raceSeriesID: null,
  seasonID: null,
  eventID: null,
  sessionID: null,
  contestantID: null,
  NRun: null,
  runSheetID: null,
};

const convertForConfig = (selection: Selection): Selection => {
  return {
    raceSeriesID: selection.raceSeriesID ?? '',
    seasonID: selection.seasonID ?? '',
    eventID: selection.eventID ?? '',
    sessionID: selection.sessionID ?? '',
    contestantID: selection.contestantID ?? '',
    NRun: selection.NRun ?? '',
    runSheetID: null,
  };
};

const parseConfig = (selection: Selection): Selection => {
  return {
    raceSeriesID: selection.raceSeriesID === '' ? null : selection.raceSeriesID,
    seasonID: selection.seasonID === '' ? null : selection.seasonID,
    eventID: selection.eventID === '' ? null : selection.eventID,
    sessionID: selection.sessionID === '' ? null : selection.sessionID,
    contestantID: selection.contestantID === '' ? null : selection.contestantID,
    NRun: selection.NRun === '' ? null : selection.NRun,
    runSheetID: null,
  };
};

const SelectionContext = createContext({
  selection: defaultSelection,
  updateSelection: (_changes: Partial<Selection>) => {},
});

export default SelectionContext;

export const SelectionContextProvider = ({
  children,
  secondary = false,
}: {
  children: ReactNode;
  secondary?: boolean;
}) => {
  const [selection, setSelection] = useState<Selection>(() =>
    secondary
      ? parseConfig(config.get('runsheet.secondarySelections'))
      : parseConfig(config.get('runsheet.mainviewSelections'))
  );

  const updateSelection = (changes: Partial<Selection>) => {
    const newSelection = { ...selection, ...changes };
    setSelection(newSelection);

    if (secondary) {
      config.set(
        'runsheet.secondarySelections',
        convertForConfig(newSelection)
      );
    } else {
      config.set('runsheet.mainviewSelections', convertForConfig(newSelection));
    }
  };

  return (
    <SelectionContext.Provider value={{ selection, updateSelection }}>
      {children}
    </SelectionContext.Provider>
  );
};
