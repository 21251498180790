import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import SidebarActionItem from './SideBarActionItem';
import {
  SideBarNavigation,
  SidebarChild,
  useAvailableMenuChildren,
} from './SidebarNavigationLinks';

const SidebarActions = ({
  item,
  onMenuItemClicked,
  showParent,
  collapsed,
}: {
  item: SidebarChild | SideBarNavigation;
  collapsed: boolean;
  onMenuItemClicked: () => void;
  showParent: boolean;
}) => {
  const navigate = useNavigate();
  const availableChildren = useAvailableMenuChildren(item.children);

  return (
    <div>
      {showParent && collapsed && (
        <Menu.Item>
          {({ active }) => (
            <button
              type="button"
              className={clsx(
                active
                  ? 'bg-gray-100  dark:bg-gray-800 dark:text-gray-100'
                  : 'text-gray-700 dark:text-gray-300',
                'block w-full px-8 py-2 text-left text-sm font-bold focus:outline-none',
                item.children &&
                  item.children.length &&
                  'border-b border-gray-200 dark:border-gray-800'
              )}
              onClick={() => {
                onMenuItemClicked();

                const link = 'href' in item ? item.href : item.link;
                if (link) {
                  navigate(link);
                  return;
                }

                item.action?.();
              }}
            >
              {item.name}
            </button>
          )}
        </Menu.Item>
      )}
      {availableChildren.map((child: SidebarChild, index) => (
        <Fragment key={child.link ?? index}>
          <SidebarActionItem
            onMenuItemClicked={onMenuItemClicked}
            item={child}
            collapsed={collapsed}
          />
        </Fragment>
      ))}
    </div>
  );
};

export default SidebarActions;
