import { HashRouter } from 'react-router-dom';
import { RenderRoutes } from '~/RenderRoutes';
import AppLayout from '~/features/common/components/AppLayout';
import ErrorBoundary from '~/features/common/components/ErrorBoundary/ErrorBoundary';
import App from './App';
import { ProvideAuth } from './features/common/context/AuthContext';

export default function AllRoutes() {
  return (
    <HashRouter>
      <ProvideAuth>
        <App>
          <AppLayout>
            <ErrorBoundary>
              <RenderRoutes />
            </ErrorBoundary>
          </AppLayout>
        </App>
      </ProvideAuth>
    </HashRouter>
  );
}
