type _PathParam<Path extends string> = Path extends `${infer L}/${infer R}`
  ? _PathParam<L> | _PathParam<R>
  : Path extends `[${infer Param}]` | `:${infer Param}`
    ? Param extends `${infer Optional}?`
      ? Optional
      : Param
    : never;

type PathParam<Path extends string> = Path extends '*' | '/*'
  ? '*'
  : Path extends `${infer Rest}/*`
    ? '*' | _PathParam<Rest>
    : _PathParam<Path>;

const replaceMultiple = (str: string, keyValueList: string[][]) => {
  let result = str;
  for (const [key, value] of keyValueList) {
    result = result.replace(key, value);
  }
  return result;
};
export const returnPath =
  <Path extends string>(path: Path) =>
  (params: { [key in PathParam<Path>]: string }) => {
    const paramsList = Object.entries(params).map(([key, value]) => [
      `:${key}`,
      value as string,
    ]);
    return replaceMultiple(path, paramsList);
  };
