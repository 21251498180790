import { faSync } from '@fortawesome/free-solid-svg-icons';
import Icon from '~/features/common/components/icons/Icon/index';

export const SessionTimeCount = ({
  timeRemainingInSeconds,
  timeRemainingString,
  sessionID,
}: {
  timeRemainingInSeconds: number;
  timeRemainingString: string;
  sessionID: string | undefined;
}) => {
  return (
    <div className="bg-white p-2 dark:bg-gray-700">
      <div className="p-1 text-center">TIME REMAINING</div>
      <div className="p-1 text-center">
        <strong>
          {!sessionID && <Icon className="mr-2" icon={faSync} spin />}
          {timeRemainingInSeconds < 0 ? 'SESSION ENDED' : timeRemainingString}
        </strong>
      </div>
    </div>
  );
};
