import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Menu } from '@headlessui/react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProLabel } from '~/features/common/components/Sidebar/components/ProLabel';
import { useIsSelfOrSubItemOpen } from '~/features/common/components/Sidebar/isSelfOrSubItemOpen';
import Icon from '~/features/common/components/icons/Icon/index';
import { useAuth } from '~/features/common/context/AuthContext';
import HoverMenuList from './HoverMenuList';
import {
  SideBarNavigation,
  SidebarChild,
  useAvailableMenuChildren,
} from './SidebarNavigationLinks';

const SidebarActionItem = ({
  item,
  onMenuItemClicked,
  collapsed,
}: {
  item: SidebarChild | SideBarNavigation;
  onMenuItemClicked: () => void;
  collapsed: boolean;
}) => {
  const actionItemRef = useRef<HTMLDivElement>(null);
  const [showSubChildren, setShowSubChildren] = useState(false);
  const availableChildren = item?.children
    ? useAvailableMenuChildren(item.children)
    : [];

  const navigate = useNavigate();
  const { isSelfOpen } = useIsSelfOrSubItemOpen(item);
  const { isProUser } = useAuth();
  return (
    <>
      <Menu.Item key={item.name}>
        {({ active }) => (
          <>
            <div
              ref={actionItemRef}
              onMouseEnter={() => setShowSubChildren(true)}
              onMouseLeave={() => {
                setShowSubChildren(false);
              }}
            >
              <button
                type="button"
                className={clsx(
                  'relative flex items-center justify-between',
                  'block w-full px-4 py-2 text-sm focus:outline-none',
                  showSubChildren && 'bg-gray-50 dark:bg-gray-800',
                  isSelfOpen && 'font-bold text-primary dark:text-blue-400',
                  item.isactive && (item.availableInLightVersion || isProUser)
                    ? 'text-gray-900 hover:bg-gray-50 dark:text-gray-100  dark:hover:bg-gray-700'
                    : 'text-gray-200 hover:bg-white  dark:text-gray-600 dark:hover:bg-gray-900'
                )}
                onClick={() => {
                  if (!item.availableInLightVersion && !isProUser) {
                    return;
                  }
                  onMenuItemClicked();
                  const link = 'href' in item ? item.href : item.link;
                  if (link) {
                    navigate(link);
                    return;
                  }

                  item.action?.();
                }}
              >
                <span className="pl-4">{item.name}</span>
                {!isEmpty(availableChildren) && (
                  <Icon
                    className="text-gray-400 dark:text-gray-100"
                    icon={faChevronRight}
                  />
                )}
                {!item.availableInLightVersion && (
                  <ProLabel collapsed={collapsed} />
                )}
              </button>

              {showSubChildren && !isEmpty(availableChildren) && (
                <>
                  <div className="relative">
                    <HoverMenuList
                      topPosition={
                        actionItemRef?.current?.getBoundingClientRect().top
                      }
                      item={item}
                      collapsed={collapsed}
                      childLevel="sub"
                      onMenuItemClicked={onMenuItemClicked}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </Menu.Item>
    </>
  );
};

export default SidebarActionItem;
