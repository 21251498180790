import { isValid } from 'date-fns';
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import formatDateFns from 'date-fns/format';
import numeral from 'numeral';

export function returnClock(date: Date | undefined) {
  if (!isValid(date) || !date) {
    return '00:00:00';
  }
  return formatDateFns(date, 'HH:mm:ss');
}

function str_pad_left(string: number, pad: string, length: number) {
  return (new Array(length + 1).join(pad) + string).slice(-length);
}

export function millisecondsToHMSString(
  time?: number | null,
  showMilliseconds = false
): string {
  if (time == null || !Number.isFinite(time)) {
    return '';
  }
  const negative = time < 0 ? '-' : '';
  const timeInSecods = Math.abs(time) / 1000;
  const hours = Math.floor(timeInSecods / 60 / 60);
  const minutes = Math.floor((timeInSecods - hours * 60 * 60) / 60);
  const seconds = numeral(timeInSecods % 60);
  const milliseconds = showMilliseconds
    ? `.${numeral((timeInSecods % 1) * 1000).format('000')}`
    : '';
  if (hours > 99) {
    return '>99:00:00';
  }
  if (hours > 0) {
    return `${negative}${numeral(hours).format('00')}:${numeral(minutes).format('00')}:${numeral(seconds).format('00')}${milliseconds}`;
  }
  if (minutes > 0) {
    return `${negative}${numeral(minutes).format('00')}:${numeral(seconds).format('00')}${milliseconds}`;
  }
  return `${negative}${numeral(seconds).format('00')}${milliseconds}`;
}

export function timeString(
  time: number | null | undefined,
  showMilliseconds = true,
  showSecondsAsMinutes = false
): string {
  if (time == null || time === 999 || !Number.isFinite(time)) {
    return '';
  }
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor((time - hours * 60 * 60) / 60);
  const seconds = numeral(time % 60);
  if (hours > 0) {
    return `${numeral(hours).format('00')}:${numeral(minutes).format('00')}`;
  }
  if (minutes > 0) {
    return `${minutes}:${seconds.format(showMilliseconds ? '00.000' : '00')}`;
  }
  if (showSecondsAsMinutes) {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const finalTime =
      str_pad_left(minutes, '0', 2) + ':' + str_pad_left(seconds, '0', 2);
    return finalTime;
  }
  return seconds.format(showMilliseconds ? '0.000' : '00');
}

export function timeHMSString(time: number | null | undefined): string {
  if (time == null || time === 999 || !Number.isFinite(time)) {
    return '';
  }
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor((time - hours * 60 * 60) / 60);
  const seconds = numeral(time % 60);

  return `${numeral(hours).format('00')}h ${numeral(minutes).format(
    '00'
  )}m ${seconds.format('00')}s`;
}
export function timeHMSStringFlat(time: number | null | undefined): string {
  if (time == null || time === 999 || !Number.isFinite(time)) {
    return '';
  }
  const negative = time < 0 ? '-' : '';
  const absTime = Math.abs(time);
  const hours = Math.floor(absTime / 60 / 60);
  const minutes = Math.floor((absTime - hours * 60 * 60) / 60);
  const seconds = numeral(absTime % 60);

  return `${negative}${numeral(hours).format('00')}:${numeral(minutes).format(
    '00'
  )}:${seconds.format('00')}`;
}
export function tHourMinuteString(tDay: string | number | Date) {
  const date = new Date(tDay);
  const hours = numeral(date.getHours()).format('00');
  const minutes = numeral(date.getMinutes()).format('00');
  return `${hours}:${minutes}`;
}
export function tDayString(tDay: string | number | Date) {
  const date = new Date(tDay);
  const hours = numeral(date.getHours()).format('00');
  const minutes = numeral(date.getMinutes()).format('00');
  const seconds = numeral(date.getSeconds()).format('00');
  return `${hours}:${minutes}:${seconds}`;
}
export function tDayStringMinutes(tDay: string | number | Date) {
  const date = new Date(tDay);
  const minutes = numeral(date.getMinutes()).format('00');
  const seconds = numeral(date.getSeconds()).format('00');
  return `${minutes}:${seconds}`;
}
export function tDayStringFull(tDay: string | number | Date) {
  const date = new Date(tDay);
  const hours = numeral(date.getHours()).format('00');
  const minutes = numeral(date.getMinutes()).format('00');
  const seconds = numeral(date.getSeconds()).format('00');
  const milliseconds = numeral(date.getMilliseconds()).format('000');
  return `${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function convertToLocalTime(utcDateTime: string | null | undefined) {
  const offset = new Date().getTimezoneOffset() * 1000 * 60;

  if (utcDateTime == null) {
    return '';
  }
  const offsetDate = new Date(utcDateTime).valueOf() - offset;
  return new Date(offsetDate).toISOString().substring(0, 19);
}

export function convertUtcToLocalTime(utcTime: string, timeZone: string) {
  const pattern = "yyyy-MM-dd'T'HH:mm:ss.SSS";
  const utcDate = utcToZonedTime(utcTime, timeZone || '');
  return format(utcDate, pattern);
}

export function convertLocalTimeToUtc(utcTime: string, timeZone: string) {
  const utcDate = zonedTimeToUtc(utcTime, timeZone || '');
  return utcDate.toISOString();
}

export function readableDayCommaTime(tDay: string | number | Date) {
  const date = new Date(tDay);
  const day = numeral(date.getDay()).format('00');
  const month = numeral(date.getMonth() + 1).format('00');
  const year = numeral(date.getFullYear()).format('0000');
  const hours = numeral(date.getHours()).format('00');
  const minutes = numeral(date.getMinutes()).format('00');
  return `${day}.${month}.${year}, ${hours}:${minutes}`;
}
