import {
  GetApiVersionDocument,
  GetApiVersionQuery,
  GetApiVersionQueryVariables,
} from './generated/graphql';
import { fetcher } from './graphql/fetcher';

export const getApiVersion = async () => {
  const data = await fetcher<GetApiVersionQuery, GetApiVersionQueryVariables>(
    GetApiVersionDocument
  )();
  return data?.version ?? '';
};
