import { LogFunctions } from 'electron-log';
import isDev from './isDev';
import isElectron from './isElectron';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const consumer = (..._: unknown[]) => {};

const noConsole: LogFunctions = {
  debug: consumer,
  error: consumer,
  info: consumer,
  log: consumer,
  silly: consumer,
  verbose: consumer,
  warn: consumer,
};

/**
 * Logger which behaves differently depending on context.
 * * If Electron, uses `electron-log`.
 * * If in web development, uses `console`.
 * * If in web production, doesn't log at all.
 */
export let logger: Console | LogFunctions;

if (isElectron()) {
  logger = window.require('electron-log');
} else if (isDev()) {
  logger = console;
} else {
  logger = noConsole;
}
