// import * as ElectronSentry from '@sentry/electron';
import * as BrowserSentry from '@sentry/browser';
import * as AppSentry from '@sentry/electron/renderer';
import { init as reactInit } from '@sentry/react';
import { getApiVersion } from './getApiVersion';

type SentryConfig = {
  dsn: string;
  debug: boolean;
  tracesSampleRate: number; // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: number; // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: number;
  integrations?: AppSentry.Replay[] | BrowserSentry.Replay[];
};

const sentryConfig: SentryConfig = {
  dsn: 'https://20aa0e597d6146ea8d91b9608432072e@o4505601866727424.ingest.sentry.io/4505606850674688',
  debug: true,
  tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
};

const replayConfig = {
  maskAllText: false,
  blockAllMedia: false,
};

const userAgent = navigator.userAgent.toLowerCase();

export async function initSentry() {
  const apiVersion = await getApiVersion();

  if (import.meta.env.VITE_CI_COMMIT_BRANCH === 'master') {
    try {
      if (userAgent.indexOf(' electron/') > -1) {
        sentryConfig.integrations = [new AppSentry.Replay(replayConfig)];
        AppSentry.init(sentryConfig, reactInit);
        AppSentry.configureScope((scope) => {
          scope.setTag('apiversion', apiVersion);
        });
      }
      if (window.location.hostname === 'oneracehub.paceteq.net') {
        BrowserSentry.init(sentryConfig);
        sentryConfig.integrations = [new BrowserSentry.Replay(replayConfig)];
        BrowserSentry.configureScope(async (scope) => {
          scope.setTag('apiversion', apiVersion);
        });
      }
    } catch (error) {
      console.error('Error initializing Sentry (client):', error);
    }
  }
}

// export async function initElectronSentry() {
//   try {
//     ElectronSentry.init({
//       dsn: 'https://20aa0e597d6146ea8d91b9608432072e@o4505601866727424.ingest.sentry.io/4505606850674688',
//     });
//     ElectronSentry.configureScope((scope) => {
//       scope.setTag('apiversion', apiVersion);
//     });
//   } catch (error) {
//     console.error('Error initializing Sentry (mainprocess):', error);
//   }
// }
