export const LoadingText = ({ classNames }: { classNames?: string }) => {
  return (
    <div className={classNames}>
      Loading
      <span className="animate-[pulse_1s_infinite_100ms]">.</span>
      <span className="animate-[pulse_1s_infinite_300ms]">.</span>
      <span className="animate-[pulse_1s_infinite_500ms]">.</span>
    </div>
  );
};
