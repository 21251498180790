// see https://github.com/sindresorhus/electron-store
// and https://json-schema.org/understanding-json-schema/

import isDev from '~/features/common/utils/isDev';
import isElectron from '~/features/common/utils/isElectron';

interface MapInterface {
  [key: string]: string | undefined;
}

const subscriptions: Array<{ key: string; callback: () => void }> = [];

let config: {
  set: (key: string, value: unknown) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get: (key: string) => any;
  onDidChange: (key: string, callback: () => void) => void;
};

if (isElectron()) {
  const store = window.require('electron-store');
  config = new store({
    schema: {
      api: {
        type: 'object',
        properties: {
          address: {
            type: 'string',
            default: isDev() ? 'dev.paceteq.net' : '',
          },
        },
        default: {},
      },
      interface: {
        type: 'object',
        properties: {
          theme: {
            type: 'string',
            default: 'system',
          },
        },
        default: {},
      },
      kpiOverview: {
        type: 'object',
        properties: {
          selectedEvents: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
          selectedRunSheets: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
          selectedConfigId: { type: 'string', default: '' },
        },
        default: {},
      },
      runsheet: {
        type: 'object',
        properties: {
          lapTableOptions: {
            type: 'object',
            properties: {
              reverseLapRows: { type: 'boolean' },
              showFuelLoadDetails: { type: 'boolean' },
              showKPI: { type: 'boolean' },
              showLapsReversed: { type: 'boolean' },
              showSessionTheo: { type: 'boolean' },
              showStats: { type: 'boolean' },
              showTimeRemaining: { type: 'boolean' },
            },
          },
          mainviewSelections: {
            type: 'object',
            properties: {
              raceSeriesID: {
                type: 'string',
              },
              seasonID: {
                type: 'string',
              },
              eventID: {
                type: 'string',
              },
              sessionID: {
                type: 'string',
              },
              contestantID: {
                type: 'string',
              },
            },
            default: {},
          },
          secondarySelections: {
            type: 'object',
            properties: {
              raceSeriesID: {
                type: 'string',
              },
              seasonID: {
                type: 'string',
              },
              eventID: {
                type: 'string',
              },
              sessionID: {
                type: 'string',
              },
              contestantID: {
                type: 'string',
              },
            },
            default: {},
          },
          style: {
            type: 'string',
            default: 'GT',
          },
        },
        default: {},
      },
      security: {
        type: 'object',
        properties: {
          username: {
            type: 'string',
          },
          jwt: {
            type: 'string',
          },
        },
        default: {},
      },
      setupOverview: {
        type: 'object',
        properties: {
          selectedEvents: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
          selectedRunSheets: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
          selectedConfigId: { type: 'string', default: '' },
        },
        default: {},
      },
      simulationOverview: {
        type: 'object',
        properties: {
          selectedConfigId: { type: 'string', default: '' },
          selectedSetupIDs: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
          selectedRunIDs: {
            type: 'array',
            items: { type: 'string' },
            default: [],
          },
        },
      },
      timesheet: {
        type: 'object',
        properties: {
          adminViewGroupingKey: {
            type: 'string',
            default: 'personID',
          },
        },
        default: {},
      },
      timezone: {
        type: 'object',
        properties: {
          preference: {
            type: 'string',
            default: 'laptop',
          },
        },
      },
      tyreBarcodeScanning: {
        type: 'object',
        properties: {
          scanPartID: {
            type: 'boolean',
            default: 'false',
          },
          showTyreWeight: {
            type: 'boolean',
            default: 'false',
          },
        },
      },
    },
    watch: true,
  });
} else {
  config = {
    set: (key: string, value: unknown) => {
      window?.localStorage?.setItem(key, JSON.stringify(value));

      subscriptions.forEach((item) => {
        if (key.startsWith(item.key)) item.callback();
      });
    },
    get: (key: string) => {
      const fallBackValues: MapInterface = {
        'interface.theme': 'system',
        'api.address': '',
        'runsheet.style': 'WSeries',
      };

      const result = window?.localStorage?.getItem(key);
      return result ? JSON.parse(result) : fallBackValues[key] || '';
    },
    onDidChange: (key: string, callback: () => void) => {
      subscriptions.push({ key, callback });
    },
  };
}

export default config;
