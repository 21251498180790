import { GroupedTimingStatus } from '~/features/common/hooks/useTimingServiceStatus/groupTimingServiceStatusUpdates';

export const getTimingServiceStatusForDisplay = (
  serviceStatus: GroupedTimingStatus | undefined
) => {
  // If there is no active status then we can return 'STOPPED'
  if (!serviceStatus) return 'STOPPED';

  const allFailedAndActiveStatuses = Object.values(serviceStatus).map(
    (config) => ({
      failed: config.failed,
      active: config.timingServiceConfig?.active,
    })
  );

  // If there are no active timing statuses then we return 'STOPPED'
  const anyActive = allFailedAndActiveStatuses.some((status) => status.active);
  if (!anyActive) return 'STOPPED';

  // If there are active timing statuses that have an error we return 'ERROR'
  const activeAndFailed = allFailedAndActiveStatuses.some(
    (status) => status.active && status.failed
  );
  if (activeAndFailed) return 'ERROR';

  // If all active timing statuses are working we return 'RUNNING'
  const allActiveAndNoneFailed = allFailedAndActiveStatuses.every(
    (status) => status.active && !status.failed
  );
  if (allActiveAndNoneFailed) return 'RUNNING';

  // This is a fallback to satisfy typescript, we should never get here
  return 'STOPPED';
};
